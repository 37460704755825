.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 0;
}

.result-block-container .result-block.show {
  opacity: 1;
}

.plan-details ul {
  list-style: none;
  padding: 0;
}
ul, ol {
  margin: 0 0 1.66667em;
  padding: 0 0 0 1.66667em;
}
user agent stylesheet
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.plan {
  font-size: 0.88889rem;
  margin: 0 auto 1.66667rem;
  max-width: 360px;
  text-align: center;
}

.palette-purple button, .palette-purple input[type="submit"], .palette-purple input[type="button"], .palette-purple input[type="reset"], .palette-purple a.button {
  background: #b5267b;
  border-color: #b5267b;
  color: #fff;
}

.btn-secondary {
  color: #fff;
  background-color: #b5267b;
  border-color: #b5267b;
}

.navbar-brand {
    margin-right: 60px;
}

@media (min-width: 320px) and (max-width: 480px) {

  .navbar-brand {
      margin-right: 10px;
  }

  .logo {
      background-image: url(/rodened_b2_300.png);
      width: 143px;
      height: 32px;
      background-repeat: no-repeat;
      background-size: cover;
  }

}
